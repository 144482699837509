.ng-select.custom {
    min-height: 0px;
    border-radius: 0;
}
.ng-select.custom .ng-dropdown-panel {
    height: auto;
    // padding: 0px 2px 1px;
}

.ng-select.custom .ng-select-container .ng-value-container .ng-input > input {
    cursor: text;
}
