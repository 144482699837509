.status-log {
    &-subtext {
        font-size: 12px;
        float: right;
        width: 50%;
    }

    &-info {
        width: 100%;
        float: left;
        clear: both;
    }

    &-clear {
        clear: both;
    }
}

/*
   This is the background of our overlay. We need it to be
   absolutely positioned within the grid, and fill from
   top to bottom, and the full width. It will also have
   a black background with 40% opacity.
*/
.grid-msg-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
}

/*
    This guy will contain our message. We want it centered
    so it's positioned absolutely with percentage-based
    offsets and dimensions. It also has some basic border
    stuff and most important is using "display: table" so
    we can vertically center its contents.
  */
.grid-msg-overlay .msg {
    opacity: 1;
    position: absolute;
    top: 30%;
    left: 20%;
    width: 60%;
    height: 50%;
    background-color: #eee;
    border-radius: 4px;
    border: 1px solid #555;
    text-align: center;
    font-size: 24px;
    display: table;
}

/*
    Lastly this is the actual message text. It uses
    display: table-cell so the vertical alignment
    works properly.
  */
.grid-msg-overlay .msg span {
    display: table-cell;
    vertical-align: middle;
}

/* styles for doc download modal */
.modal {
    opacity: 1;
}
.modal-backdrop.fade {
    opacity: 0.5;
}

.modal-dialog {
    margin-top: 20%;
}
